<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>จัดการผู้ใช้งาน</b></h3>
        <small>
          <router-link to="/setting/user">จัดการผู้ใช้งาน</router-link> >
          <b>{{ userData.userId ? "แก้ไขผู้ใช้งาน" :"เพิ่มผู้ใช้งาน" }}</b>
        </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          v-if="userPermission=='create' || userPermission=='edit'"          
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="save"
          :disabled="isLoading"
        >
        <b-spinner v-if="isLoading" small class="align-middle"></b-spinner>
        <span v-else>
          <feather-icon icon="SaveIcon" />
          บันทึก
        </span>
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.push('/setting/user')"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <!-- START SECTION FORM วันเดินทาง--->
        <b-card>
          <b-row>
            <b-col
              ><h4><b>ข้อมูลส่วนตัว</b></h4></b-col
            >
          </b-row>
          <!--- START (ชื่อ-สกุล) --->
          <b-row class="mt-1">
            <b-col md="3" class="">
              <label>ชื่อ <span class="text-danger">*</span></label>
              <b-form-input    
                placeholder="ชื่อ"           
                size="sm"
                v-model="userData.fname"
              />
            </b-col>

            <b-col md="3" class="">
              <label>นามสกุล</label>
              <b-form-input 
                placeholder="นามสกุล"             
                size="sm"
                v-model="userData.lname"
              />
            </b-col>

            <b-col cols="3" class="">
              <label>เบอร์โทร</label>
              <b-form-input
                placeholder="0987654321"
                size="sm"
                v-model="userData.phone"
              />
            </b-col>

            <b-col cols="3" class="">
              <label>Email</label>
              <b-form-input
                placeholder="admin@gmail.com"
                size="sm"
                v-model="userData.email"
              />
            </b-col>
          </b-row>
          <!--- END (ชื่อ-สกุล) --->

          <!--- START ROW ที่อยู่ --->
          <b-row class="mt-1">
            <b-col md="12">
              <label>ที่อยู่</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="ที่อยู่"
                rows="2"
                size="sm"
                v-model="userData.address"
              />
            </b-col>
          </b-row>
          <!--- END ที่อยู่ --->

          <b-row class="mt-2">
            <b-col
              ><h4><b>ข้อมูลการเข้าสู่ระบบ</b></h4></b-col
            >
          </b-row>

          <!--- START (ชื่อผู้ใช้งาน) --->
          <b-row class="mt-1">
            <b-col md="3" class="">
              <label>ชื่อผู้ใช้งาน <span class="text-danger">*</span></label>
              <b-form-input
                placeholder="support2023"
                size="sm"
                v-model="userData.username"
              />
            </b-col>

            <b-col md="3" class="">
              <label>รหัสผ่าน <span class="text-danger">*</span></label>
              <b-form-input
                placeholder="@sItsupport123"
                size="sm"
                value="@sItsupport123"
                v-model="userData.password"
              />
              <small class="text-danger">{{ validatePassword(userData.password) }}</small>
            </b-col>
            <b-col md="3" class="">
              <label>ยืนยันรหัสผ่าน <span class="text-danger">*</span></label>
              <b-form-input
                placeholder="password1234"
                size="sm"
                v-model="userData.confirmPassword"
              />
              <small class="text-danger" v-if="userData.password!==userData.confirmPassword">**รหัสผ่านไม่ตรงกัน</small>
            </b-col>
          </b-row>
          <!--- END (ชื่อผู้ใช้งาน) --->

          <b-row class="mt-1">
            <b-col cols="3">
              <label>ประเภทผู้ใช้งาน</label>
              <v-select
                id="brand"
                v-model="userTypeSelected"
                label="title"
                :options="userTypeOption"
                :clearable="false"
                class="select-size-sm"                
              />
            </b-col>
            <b-col cols="3">
              <label>แผนก</label>
              <v-select
                id="brand"
                v-model="departmentSelected"
                label="title"
                :options="departmentOption"
                :clearable="false"
                class="select-size-sm"                
              />
            </b-col>

            <b-col cols="3">
              <label>สถานะ</label>
              <v-select
                id="brand"
                v-model="statusSelected"
                label="title"
                :options="statusOptions"
                :clearable="false"
                class="select-size-sm"                
              />
            </b-col>
          </b-row>

          <b-row class="mt-2 mb-1">
            <b-col
              ><h4><b>Roles <span class="text-danger">*</span></b></h4></b-col
            >
          </b-row>

          <b-row v-for="(role, index) in roles" :key="role.name" class="mt-1">
            <b-col cols="3">{{index+1}}.  {{ role.roleName }}</b-col>
            <b-col cols="2">
              <b-form-radio v-model="userData.roleId" name="some-radios" :value="role.roleId"></b-form-radio>
            </b-col>
          </b-row>
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>  
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BPagination,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BThead,
  BTbody,
  BFormRadio,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import UserService from "@/services/user";
import RoleService from "@/services/setting/role";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    BPagination,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    BFormRadio,
    vSelect,
    BSpinner
  },
  data() {
    return {
      userTypeSelected: { title: "พนักงาน" , value:"employee" },
      departmentSelected: { title: "Other" , value:"Other" },
      statusSelected: { title: "เปิด", value: 1 },
      userTypeOption: [{ title: "พนักงาน" , value:"employee" }, { title: "ลูกค้า", value: "customer" }],
      departmentOption: [{ title: "Helpdesk" , value:"Helpdesk" }, { title: "CM", value: "CM" }, { title: "Accounting", value: "Accounting" }, { title: "Stock", value: "Stock" }, { title: "Other", value: "Other" }],
      statusOptions: [
        { title: "เปิด", value: 1 },
        { title: "ปิด", value: 0 },
      ],
      roles: [],
      userData:{},
      isLoading:false
    };
  },

  computed:{
    userPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST005")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission        
    },
    invalidForm(){
      if(!this.userData.fname){
        return true
      }

      if(!this.userData.username || !this.userData.password || !this.userData.confirmPassword){
        return true
      }

      if(this.userData.password !== this.userData.confirmPassword){
        return true
      }

      if(!this.userData.roleId){
        return true
      }
    }
  },
  
  mounted() {
    this.getUserById()
    this.getRoles()
  },
  methods: {
    validateForm(){
      if(!this.userData.fname){
        alert("กรุณากรอกชื่อ")
        return true
      }

      if(!this.userData.username){
        alert("กรุณากรอก Username")
        return true
      }


      //ทำเฉพาะตอนสร้าง
      if(!this.userData.userId){
        if(!this.userData.password){
          alert("กรุณากรอก Password")
          return true
        }

        if(this.validatePassword(this.userData.password)){
          alert("กรุณากรอก Password ให้ถูกต้องตาม Policy โดยมี a-z A-Z ตัวอักขระพิเศษ ตัวเลขและมากกว่า 6 ตัว")
          return true
        }


        if(this.userData.password !== this.userData.confirmPassword){
          alert("Password และ Confirm Password ให้ตรงกัน")
          return true
        }
      }


      if(!this.userData.roleId){
        alert("กรุณาเลือกสิทธิ์")
        return true
      }

      return false
    },
    validatePassword(password, type="string") {
      const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/;      
      const validated =  re.test(password);
      if(password && !validated){
        if(type=="boolean"){
          return true
        }else{
          return "** ต้องมี a-z A-Z ตัวอักขระพิเศษ ตัวเลขและมากกว่า 6 ตัว"
        }
        
      }
    },
    async getUserById(){
      const paramUserId = this.$route.params.userId
      const result = await UserService.GetUserById(paramUserId)
      this.userData = result.data     
      this.userTypeSelected = this.userTypeOption.find(f=>f.value == result.data.type ) || { title: "พนักงาน" , value:"employee" }
      this.departmentSelected = this.departmentOption.find(f=>f.value == result.data.department ) || { title: "Other" , value:"Other" }      
    },
    async getRoles(){      
      const result = await RoleService.GetRoles()
      this.roles = result.data.data      
    },


    async save(){
      const hasError = this.validateForm()
      if(hasError) return

      if(this.userData.userId){
        await this.updateUser()
      }else{
        await this.insertUser()
      }
    },
    async insertUser(){
      this.isLoading = true
      const obj = {
        ...this.userData,
        active:this.statusSelected.value, 
        type: this.userTypeSelected.value,
        department: this.departmentSelected.value
      }
      const result = await  UserService.InsertUser(obj)
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `User`,
              icon: "CheckIcon",
              variant: "success",
              text: `เพิ่มรายการเรียบร้อย`,
            },
          });
          this.$router.push("/setting/user")        
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `User`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการเพิ่มรายการ`,
            },
          });
        }

        this.isLoading = false
    }, 

    async updateUser(){
      this.isLoading = true
      const obj = {
        ...this.userData,
        active:this.statusSelected.value,       
        type: this.userTypeSelected.value,
        department: this.departmentSelected.value
      }
      const result = await  UserService.UpdateUser(obj,obj.userId)
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `User`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขรายการเรียบร้อย`,
            },
          });
          this.$router.push("/setting/user")        
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `User`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการแก้ไขรายการ`,
            },
          });
        }

        this.isLoading = false
    }, 
  },
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
